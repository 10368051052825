import React from "react"
import PropTypes from "prop-types"
import ContactBlock from "../ContactBlock/contactBlock"
import Form from "../Form/form"
import BuyForm from "../BuyForm/buyForm"
import ScrollToTop from "../ScrollToTop/scrollToTop"
import { StyledFooter } from "./styles"
// import { StyledFooter, DocumentButtonWrapper, DocumentButton } from "./styles"

const Footer = ({
  formSignal,
  has4dContactInfo,
  hasForm,
  hasBuyForm,
  hasBuyButton,
  invertedScrollToTop,
}) => {
  return (
    <StyledFooter>
      {/* {!hasBuyForm && hasBuyButton && (
        <DocumentButtonWrapper>
          <DocumentButton to="/kopanmalan"></DocumentButton>
        </DocumentButtonWrapper>
      )} */}
      {hasBuyForm && <BuyForm />}
      {/* <ContactBlock has4dContactInfo={has4dContactInfo} /> */}
      {hasForm && <Form formSignal={formSignal} />}
      <ScrollToTop isInverted={invertedScrollToTop} />
    </StyledFooter>
  )
}

Footer.propTypes = {
  hasForm: PropTypes.bool,
  hasBuyForm: PropTypes.bool,
  hasBuyButton: PropTypes.bool,
  invertedScrollToTop: PropTypes.bool,
}

Footer.defaultProps = {
  hasForm: true,
  hasBuyForm: false,
  hasBuyButton: true,
  invertedScrollToTop: false,
}

export default Footer
